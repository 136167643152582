


import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TEvent } from '@/_types/event.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TUser } from '@/_types/user.type';
import { TContact } from '@/_types/contact.type';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import EwInput from '@/_modules/standalone-company/components/UI/ew-input/ew-input.vue';
import EwCheckbox from '@/_modules/standalone-company/components/UI/ew-checkbox/ew-checkbox.vue';
import EwPassword from '@/_modules/standalone-company/components/UI/ew-password/ew-password.vue';
import EwPopUp from '@/_modules/standalone-company/components/ew-pop-up/ew-pop-up.vue';
import { email, minLength, required } from 'vuelidate/lib/validators';
import { TVuelidateRuleSet } from '@/_types/vuelitation-rule-set.type';
import { Validations } from 'vuelidate-property-decorators';
import loginApi, {
  TPasswordChangeConfirmRequestParams,
  TSendLoginRequestParams,
  TUserAuthData
} from '@/_api/login/login.api';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';
import HelpCrunchService from '@/_services/help-crunch.service';
import UtilsHelper from '@/_helpers/utils.helper';
import { Location } from 'vue-router';
import { TranslateResult } from 'vue-i18n';
import { TEventSettings } from '@/_types/event-settings.type';
import {
  DEFAULT_CONTACT_INVITER_SETTINGS,
  CONTACT_INVITER_SETTINGS_KEY_NAME,
} from '@/_modules/promo-cabinet/components/cabinet-contact-inviter-settings/cabinet-contact-inviter-settings.vue';
import OauthButtons from '@/_components/oauth-buttons/oauth-buttons.vue';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';

const ENVIRONMENT_NAME = process.env.VUE_APP_ENV;
const RESEND_CODE_SECONDS = 50;
export const PASSWORD_MIN_LENGTH = 5;

// TODO: refactor this whole file. It is a mess.

type TEwInputComponent = {
  inputElement: HTMLInputElement;
}

@Component({
  components: {
    SimplePopup,
    EwButton,
    EwInput,
    EwPopUp,
    EwCheckbox,
    EwPassword,
    OauthButtons,
  }
})
export default class EwAuth extends Vue {
  @Ref('confirmCodePart1') confirmCodePart1: TEwInputComponent;
  @Ref('confirmCodePart2') confirmCodePart2: TEwInputComponent;
  @Ref('confirmCodePart3') confirmCodePart3: TEwInputComponent;
  @Ref('refSignInCheck') refSignInCheck: TEwInputComponent;
  @Ref('refSignIn') refSignIn: TEwInputComponent;
  @Ref('refSignUp') refSignUp: TEwInputComponent;
  @Ref('refMyName') refMyName: TEwInputComponent;

  @Getter('_eventStore/event') event: TEvent;
  @Getter('_eventStore/eventSettings') eventSettings: TEventSettings;
  @Getter('_eventStore/hasAgreedToBecomeAContact') hasAgreedToBecomeAContact: boolean;
  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('authStore/isStandaloneAuthVisible') isStandaloneAuthVisible: boolean;
  @Getter('authStore/isEmailRegistered') isEmailRegistered: boolean;
  @Getter('authStore/authPopupTargetRoute') authPopupTargetRoute: Location;
  @Getter('authStore/authError') authError: ApiErrorResponseData;
  @Getter('authStore/authToken') userToken: string;
  @Getter('authStore/userData') userData: TUserAuthData;
  @Getter('authStore/deviceId') deviceId: string;
  @Getter('authStore/confirmId') confirmationId: string;
  @Getter('authStore/forgotPasswordRequestErrorText') forgotPasswordRequestErrorText: string;
  @Getter('authStore/changePasswordRequestErrorText') changePasswordRequestErrorText: string;
  @Getter('_userStore/user') user: TUser;
  @Getter('promoPageStore/contact') myself: TContact;
  @Getter('promoPageStore/individualPromoPage') company: TPromoPage;

  @Action('authStore/emailLookup') emailLookup: (params: { email: string }) => Promise<boolean>;
  @Action('authStore/signIn') signIn: (params: TSendLoginRequestParams) => Promise<void>;
  @Action('authStore/signUp') signUp: (params: TSendLoginRequestParams) => Promise<void>;
  @Action('authStore/setStandaloneAuthVisibility') setStandaloneAuthVisibility: (isVisible: boolean) => void;
  @Action('_eventStore/showBecomingAContactConfirm') showBecomingAContactConfirm: () => void;
  @Action('_eventStore/hideBecomingAContactConfirm') hideBecomingAContactConfirm: () => void;
  @Action('promoPageStore/contactLookup') contactLookup: (params: { eventId: number }) => Promise<{ contact_exists: boolean }>;
  @Action('_eventStore/confirmBecomingAContact') confirmBecomingAContact: () => Promise<void>;
  @Action('_eventStore/setPrivateEventDialogVisibility') setPrivateEventDialogVisibility: (isVisible: boolean) => void;
  @Action('promoPageStore/getContact') getContact: (params: { eventId: number }) => Promise<TContact>;

  public isCheckLoginPopupVisible: boolean = false;
  public isSignInPasswordPopupVisible: boolean = false;
  public isSignUpPasswordPopupVisible: boolean = false;
  public isConfirmCodePopupVisible: boolean = false;
  public isTicketCodePopupVisible: boolean = false;

  public isConfirmCodeVerified: boolean = false;
  public confirmCodeParts: string[] = [];
  public resendCodeCounter: number = RESEND_CODE_SECONDS;
  public resendCodeCounterIntervalId: number;

  public whereToGetCodeMessage: string = '';

  public userEmail: string = '';
  public emailIsIncorrect: TranslateResult = '';
  public userPassword: string = '';
  public passwordIsIncorrect: TranslateResult = '';
  public passwordMinLength: number = PASSWORD_MIN_LENGTH;

  public ticketCode: string = '';
  public ticketCodeIsIncorrect: TranslateResult = '';

  public myName: string = '';
  public mySurname: string = '';
  public myCountry: string = '';
  public myCompanyName: string = '';
  public myJobPosition: string = '';
  public myPhoneNumber: string = '';

  public myNameIsIncorrect: TranslateResult = '';
  public mySurnameIsIncorrect: TranslateResult = '';
  public myCountryIsIncorrect: TranslateResult = '';
  public myCompanyNameIsIncorrect: TranslateResult = '';
  public myJobPositionIsIncorrect: TranslateResult = '';
  public myPhoneNumberIsIncorrect: TranslateResult = '';

  public isAgreedToEmailing: boolean = false;

  @Validations()
  public readonly validations: TVuelidateRuleSet<string> | any = {
    userEmail: {
      email: email
    },
    userPassword: { minLength: minLength(PASSWORD_MIN_LENGTH) },
    myName: { required },
    mySurname: { required },
    myCountry: { required },
    myCompanyName: { required },
    myJobPosition: { required },
    myPhoneNumber: { required },
  };

  public get eventId(): number {
    return this.event && this.event.id;
  }

  public get eventLanguages(): string[] {
    if (!this.event || !this.event.languages || !this.event.languages.length) {
      return ['en'];
    }
    return this.event.languages;
  }

  public get apiAuthErrorText(): string {
    return (this.authError && this.authError.error) || '';
  }

  public get myContactName(): string {
    return (this.myself && this.myself.name) || '';
  }

  public get myContactSurname(): string {
    return (this.myself && this.myself.surname) || '';
  }

  public get myContactCompanyPosition(): string {
    return (this.myself && this.myself.company_position) || '';
  }

  public get myContactCountry(): string {
    return (this.myself && this.myself.country) || '';
  }

  public get myContactCompanyName(): string {
    return (this.myself && this.myself.company_name) || '';
  }

  public get myContactPhone(): string {
    return (this.myself && this.myself.phone) || '';
  }

  public get isHideEwPopupButtonVisible(): boolean {
    return this.$route.name !== 'auth-page';
  }

  public get isEventsWalletDomain(): boolean {
    const originDomain: string = ((window && window.location && window.location.origin) || '')
      .replace(window.location.protocol, '')
      .replace('//', '');
    return originDomain.indexOf('eventswallet.') >= 0;
  }

  public get isOauthButtonsVisible(): boolean {
    return this.isEventsWalletDomain && !this.isCN;
  }

  public get isRestoreButtonDisabled(): boolean {
    return !(/^\d{4}$/.test(this.confirmCode)) || !this.userPassword;
  }

  // TODO: try to reuse isAllRequiredContactInfoProvided from inside this getter
  public get isUpdateContactButtonDisabled(): boolean {
    const fieldSettings = this.contactInviterFieldSettings;
    const isCountryRequired = (fieldSettings && fieldSettings.country) ? fieldSettings.country.isRequired : false;
    const isCompanyNameRequired = (fieldSettings && fieldSettings.companyName) ? fieldSettings.companyName.isRequired : false;
    const isJobPositionRequired = (fieldSettings && fieldSettings.jobPosition) ? fieldSettings.jobPosition.isRequired : false;
    const isPhoneRequired = (fieldSettings && fieldSettings.phone) ? fieldSettings.phone.isRequired : false;
    return this.$v.mySurname.$invalid
      || this.$v.myName.$invalid
      || (isCountryRequired ? this.$v.myCountry.$invalid : false)
      || (isCompanyNameRequired ? this.$v.myCompanyName.$invalid : false)
      || (isJobPositionRequired ? this.$v.myJobPosition.$invalid : false)
      || (isPhoneRequired ? this.$v.myPhoneNumber.$invalid : false);
  }

  public get isAllRequiredContactInfoProvided(): boolean {
    const fieldSettings = this.contactInviterFieldSettings;
    const isCountryRequired = (fieldSettings && fieldSettings.country) ? fieldSettings.country.isRequired : false;
    const isCompanyNameRequired = (fieldSettings && fieldSettings.companyName) ? fieldSettings.companyName.isRequired : false;
    const isJobPositionRequired = (fieldSettings && fieldSettings.jobPosition) ? fieldSettings.jobPosition.isRequired : false;
    const isPhoneRequired = (fieldSettings && fieldSettings.phone) ? fieldSettings.phone.isRequired : false;
    return this.myContactName !== ''
      && this.myContactSurname !== ''
      && (isCountryRequired ? this.myContactCountry !== '' : true)
      && (isCompanyNameRequired ? this.myContactCompanyName !== '' : true)
      && (isJobPositionRequired ? this.myContactCompanyPosition !== '' : true)
      && (isPhoneRequired ? this.myContactPhone !== '' : true);
  }

  public get confirmCode(): string {
    if (this.confirmCodeParts[0] && this.confirmCodeParts[1] && this.confirmCodeParts[2] && this.confirmCodeParts[3]) {
      return this.confirmCodeParts.join('');
    }
    return '';
  }

  // TODO: type? export some type from cabinet-contact-inviter-settings?
  public get contactInviterFieldSettings(): any {
    const remoteSettingsLayout: any = (this.eventSettings && this.eventSettings.layout) || null;
    const contactInviterSettings: any = (remoteSettingsLayout && remoteSettingsLayout[CONTACT_INVITER_SETTINGS_KEY_NAME]) || DEFAULT_CONTACT_INVITER_SETTINGS;
    return contactInviterSettings.field_settings;
  }

  public get resendCodeCounterFormatted(): string {
    return '00:' + this.resendCodeCounter.toFixed(0).padStart(2, '0');
  }

  public get isSaveEventFlow(): boolean {
    return !!(this.$route.query && this.$route.query.flow && !Array.isArray(this.$route.query.flow) && this.$route.query.flow.toLowerCase().trim() === 'saveevent');
  }

  public get isContactInfoPopupVisible(): boolean {
    return !this.isSaveEventFlow && this.eventId && this.isAuthenticated && this.myself && !this.isAllRequiredContactInfoProvided;
  }

  public get myCountryErrorText(): string {
    if (!this.contactInviterFieldSettings || !this.contactInviterFieldSettings.country || !this.contactInviterFieldSettings.country.isRequired) {
      return '';
    }

    return this.$v.myCountry.$dirty && this.$v.myCountry.$invalid ? this.$t('contacts.popups.contactInfoInviter.fieldErrors.country') as string : '';
  }

  public get myCompanyNameErrorText(): string {
    if (!this.contactInviterFieldSettings || !this.contactInviterFieldSettings.companyName || !this.contactInviterFieldSettings.companyName.isRequired) {
      return '';
    }

    return this.$v.myCompanyName.$dirty && this.$v.myCompanyName.$invalid ? this.$t('contacts.popups.contactInfoInviter.fieldErrors.companyName') as string : '';
  }

  public get myJobPositionErrorText(): string {
    if (!this.contactInviterFieldSettings || !this.contactInviterFieldSettings.jobPosition || !this.contactInviterFieldSettings.jobPosition.isRequired) {
      return '';
    }

    return this.$v.myJobPosition.$dirty && this.$v.myJobPosition.$invalid ? this.$t('contacts.popups.contactInfoInviter.fieldErrors.jobPosition') as string : '';
  }

  public get myPhoneNumberErrorText(): string {
    if (!this.contactInviterFieldSettings || !this.contactInviterFieldSettings.phone || !this.contactInviterFieldSettings.phone.isRequired) {
      return '';
    }

    return this.$v.myPhoneNumber.$dirty && this.$v.myPhoneNumber.$invalid ? this.$t('contacts.popups.contactInfoInviter.fieldErrors.phone') as string : '';
  }

  public get isOptionalFieldsHeadingVisible(): boolean {
    const defaultRequiredFieldKeys: string[] = Object
      .keys(DEFAULT_CONTACT_INVITER_SETTINGS.field_settings)
      .filter(key => {
        return !!DEFAULT_CONTACT_INVITER_SETTINGS.field_settings[key].isRequired;
      });
    const localRequiredFieldKeys: string[] = Object
      .keys(this.contactInviterFieldSettings)
      .filter(key => {
        return !!this.contactInviterFieldSettings[key].isRequired;
      });

    return defaultRequiredFieldKeys.length === localRequiredFieldKeys.length; // N.B.: this is currently possible only if field isRequired settings are default, because we force name and lastName isRequired = true
  }

  public get isCN(): boolean {
    return (ENVIRONMENT_NAME === 'cn');
  }

  @Watch('isAuthenticated')
  public async onIsAuthenticatedChange(): Promise<void> {
    if (!this.isAuthenticated) {
      return;
    }

    if (this.$route.name === 'auth-page') {
      await this.setStandaloneAuthVisibility(false);
      this.closeSignUpPasswordPopup();
      this.closeSignInPasswordPopup();
    }

    if (this.event) {
      await this.manageAuthOnEventPage();
      return;
    }

    await this.authRedirectToSavedRoute();
  }

  @Watch('confirmCodeParts')
  public onConfirmCodePartsChange(): void {
    [this.confirmCodePart1, this.confirmCodePart2, this.confirmCodePart3].forEach((nextDigit, index) => {
      if (this.confirmCodeParts[index]) {
        nextDigit.inputElement.focus();
      }
    });
  }

  @Watch('myself', { deep: true })
  public onMyselfChange(): void {
    this.initContactInviterFields();

    if (this.myself && this.isAuthenticated && !this.isAllRequiredContactInfoProvided) {
      this.$nextTick(() => {
        try {
          this.refMyName.inputElement.focus();
        } catch {}
      });
    }
  }

  @Watch('isStandaloneAuthVisible', { immediate: true })
  private onIsStandaloneAuthVisibleChange(newVal: boolean): void {
    if (!newVal) {
      this.hidePopups();
      return;
    }

    if (this.isAuthenticated && this.event && !this.event.personal.has_access) {
      if (newVal) {
        this.isTicketCodePopupVisible = true;
      }
    } else {
      this.isCheckLoginPopupVisible = this.isStandaloneAuthVisible;
    }

    if (this.isCheckLoginPopupVisible) {
      this.$nextTick(() => {
        this.refSignInCheck.inputElement.focus();
      });
    }
  }

  @Watch('hasAgreedToBecomeAContact')
  private async onHasAgreedToBecomeAContactChange(newVal: boolean): Promise<void> {
    if (!newVal) {
      return;
    }
    await this.$store.dispatch('eventStore/toggleParticipation', {
      event_id: this.$route.params.eventId,
      going: true
    });
    this.hidePopups();
    await this.getContact({ eventId: this.eventId });
    this.initContactInviterFields();

    //
    if (this.authPopupTargetRoute) {
      try {
        await this.$router.push(this.authPopupTargetRoute);
      } catch {
        /* ignore */
      }
    } else {
      if (!this.isRedirectedByQueryEventParam()) {
        this.redirectToDefaultRoute();
      }
    }

  }

  public async manageAuthOnEventPage(): Promise<void> {
    if (!this.isAuthenticated || !this.userToken || !this.userData || !this.event) {
      return;
    }

    if (this.event.access_type === 'free') {
      if (!this.event.is_private) {
        this.setStandaloneAuthVisibility(false);
        if (!this.myself) {
          const contactExisting = (await this.contactLookup({ eventId: this.eventId })).contact_exists || false;
          if (!contactExisting) {
            this.showBecomingAContactConfirm();
            return;
          }
        }
        this.confirmBecomingAContact();
        return;
      }
    } else {
      // The following refreshes event.personal.has_access:
      await this.$store.dispatch('_eventStore/reset');
      await this.$store.dispatch('_eventStore/getEvent', this.$route.params.eventId);

      if (this.event.personal.has_access) {
        await this.authRedirectToSavedRoute();
        return;
      } else {
        this.isTicketCodePopupVisible = true;
      }

      /* else if (this.event.is_private) {
        this.setPrivateEventDialogVisibility(true);
      } */

      this.hidePopups();
      this.hideBecomingAContactConfirm();
      this.initContactInviterFields();
    }

  }

  public hidePopups(): void {
    this.isCheckLoginPopupVisible = false;
    this.isSignInPasswordPopupVisible = false;
    this.isSignUpPasswordPopupVisible = false;
    this.isConfirmCodePopupVisible = false;
  }

  public cleanMyPhoneNumber(): void {
    const cleanValue: string = this.myPhoneNumber.replace(/[^\d]/g, '');
    this.myPhoneNumber = cleanValue !== '' ? '+' + cleanValue : '';
  }

  public async updateContact(): Promise<void> {
    // this.$v.$touch();
    if (this.isUpdateContactButtonDisabled || !this.event) {
      return;
    }

    const payload: any = {
      event_id: this.eventId,
      name: this.myName,
      surname: this.mySurname,
      country: this.myCountry,
      company_name: this.myCompanyName,
      company_position: this.myJobPosition,
      phone: this.myPhoneNumber,
      tags: [],
      lang: ''
    };

    const success = await Promise.all(this.eventLanguages.map(async (langCode) => {
      payload.lang = langCode;
      await this.$store.dispatch('promoPageStore/editContact', payload);
    }));

    if (success && success.length) {
      await this.onUpdateContactSuccess();
    }
  }

  public async onUpdateContactSuccess(): Promise<void> {
    // TODO: this code is somewhat similar to manageAuthOnEventPage, can it be combined?
    if (this.event.access_type === 'free') {
      if (this.$route.path.indexOf('promo') === -1) { // Needed for standalone company auth flow
        await this.authRedirectToSavedRoute(true);
      }
    } else {
      // We have to update personal.has_access to check it below
      await this.$store.dispatch('_eventStore/reset');
      await this.$store.dispatch('_eventStore/getEvent', this.$route.params.eventId);

      if (this.event.personal && this.event.personal.has_access) {
        if (this.$route.name !== 'promo-page-cabinet-event-settings') {
          await this.authRedirectToSavedRoute();
        }
      } else if (this.event.personal && !this.event.personal.has_access) {
        this.isTicketCodePopupVisible = true;
      }
    }
  }

  public onResendCodeClick(): void {
    if (this.resendCodeCounter <= 0) {
      loginApi.resendCode({ confirmation_id: this.confirmationId });
      this.resendCodeCounter = RESEND_CODE_SECONDS;
    }
  }

  public startResendCodeCounter(): void {
    this.resendCodeCounterIntervalId = window.setInterval(() => {
      if (this.resendCodeCounter > 0) {
        this.resendCodeCounter--;
        return;
      }
      this.resendCodeCounter = 0;
    }, 1000);
  }

  public stopResendCodeCounter(): void {
    window.clearInterval(this.resendCodeCounterIntervalId);
    this.resendCodeCounter = RESEND_CODE_SECONDS;
  }

  public async signUpReq(): Promise<void> {
    if (!this.$v.userPassword.minLength) {
      this.passwordIsIncorrect = 'Password is too short'; // TODO: add translations
      return;
    }

    if (!this.isAgreedToEmailing) {
      return;
    }

    await this.signUp({
      login: this.userEmail,
      password: this.userPassword,
      platform: 'WEB',
      device_id: this.deviceId,
    });

    if (this.apiAuthErrorText) {
      this.passwordIsIncorrect = this.apiAuthErrorText;
      return;
    }

    this.dataLayerPush({
      event: 'registration-form-submit',
      formType: 'registration'
    });

    if (this.eventId && this.isAuthenticated) {
      await this.$store.dispatch('promoPageStore/getContact', { eventId: this.eventId });
    }

  }

  public async signInReq(): Promise<void> {
    if (!this.userPassword || !this.userEmail) {
      return;
    }

    await this.signIn({
      login: this.userEmail,
      password: this.userPassword,
      platform: 'WEB',
      device_id: this.deviceId,
    });

    if (this.apiAuthErrorText) {
      this.passwordIsIncorrect = this.apiAuthErrorText;
      if (this.apiAuthErrorText === 'Wrong login or password!') {
        this.passwordIsIncorrect = this.$t('authPage.screens.phoneEmail.errors.wrongLoginOrPassword') as string;
      }
      return;
    }

    this.dataLayerPush({
      event: 'sign-in-form-submit',
      formType: 'sign-in'
    });
  }

  public async emailCheck(): Promise<void> {
    if (!this.$v.userEmail.email) {
      this.emailIsIncorrect = this.$t('errors.validation.email_invalid');
      return;
    }

    await this.emailLookup({ email: this.userEmail });

    if (this.authError) {
      this.emailIsIncorrect = 'Too many requests'; // TODO: add translations
      return;
    }

    this.isCheckLoginPopupVisible = false;
    this.isSignInPasswordPopupVisible = this.isEmailRegistered;
    this.isSignUpPasswordPopupVisible = !this.isEmailRegistered;

    this.$nextTick(() => {
      (this.isEmailRegistered ? this.refSignIn : this.refSignUp).inputElement.focus();
    });

  }

  public closeCheckLoginPopup(): void {
    this.isCheckLoginPopupVisible = false;
    this.setStandaloneAuthVisibility(false);
    this.userEmail = '';
    this.userPassword = '';
  }

  public closeSignInPasswordPopup(): void {
    this.isSignInPasswordPopupVisible = false;
    this.setStandaloneAuthVisibility(false);
    this.userEmail = '';
    this.userPassword = '';
  }

  public closeSignUpPasswordPopup(): void {
    this.isSignUpPasswordPopupVisible = false;
    this.setStandaloneAuthVisibility(false);
    this.userEmail = '';
    this.userPassword = '';
  }

  public closeConfirmCodePopup(): void {
    this.stopResendCodeCounter();
    this.isConfirmCodePopupVisible = false;
    this.setStandaloneAuthVisibility(false);
    this.userEmail = '';
    this.userPassword = '';
  }

  public closeHasAccessPopup(): void {
    this.isTicketCodePopupVisible = false;
    this.whereToGetCodeMessage = '';
    this.setStandaloneAuthVisibility(false);
  }

  public showConfirmCodePopup(): void {
    this.getConfirmationId();
    this.startResendCodeCounter();
    this.isConfirmCodePopupVisible = true;
    this.isSignInPasswordPopupVisible = false;
    this.isSignUpPasswordPopupVisible = false;
    this.userPassword = '';
  }

  public initContactInviterFields(): void {
    this.myName = this.myContactName;
    this.mySurname = this.myContactSurname;
    this.myCompanyName = this.myContactCompanyName;
    this.myJobPosition = this.myContactCompanyPosition;
    this.myPhoneNumber = this.myContactPhone;
  }

  public async getConfirmationId(): Promise<void> {
    await this.$store.dispatch('authStore/forgotPassword', this.userEmail);
    if (this.confirmationId && !this.forgotPasswordRequestErrorText) {
      this.isConfirmCodeVerified = true;
    }
    if (this.forgotPasswordRequestErrorText) {
      this.passwordIsIncorrect = this.forgotPasswordRequestErrorText;
    }
  }

  public async restorePassword(): Promise<void> {
    if (this.isConfirmCodeVerified) {
      const payload: TPasswordChangeConfirmRequestParams = {
        confirmation_id: this.confirmationId,
        code: this.confirmCode,
        password: this.userPassword,
        platform: 'WEB',
        device_id: this.deviceId,
      };
      await this.$store.dispatch('authStore/changePassword', payload);
      if (this.changePasswordRequestErrorText) {
        this.passwordIsIncorrect = this.changePasswordRequestErrorText;
      }
    }
  }

  public openSupportChat(): void {
    const helpCrunchInstance = HelpCrunchService._helpCrunch;
    if (!helpCrunchInstance) {
      window.setTimeout(() => { this.openSupportChat(); }, 1000);
      return;
    }
    helpCrunchInstance('openChat');
  }

  public async sendTicketCode(): Promise<void> {
    const response = await this.$store.dispatch('eventStore/activateCode', {
      event_id: this.$route.params.eventId,
      code: this.ticketCode
    });

    if (response && response.status === 202) {
      this.isTicketCodePopupVisible = false;
      this.whereToGetCodeMessage = '';
      await this.$store.dispatch('authStore/setAuthPopupVisible', { isAuthPopupVisible: false });
      await this.$store.dispatch('_eventStore/refresh');
      await this.$store.dispatch('promoPageStore/refresh'); // AW-1622
      await this.authRedirectToSavedRoute();
    } else {
      this.ticketCodeIsIncorrect = this.$t('promoAccessCheck.accessNotGranted');
    }
  }

  public async authRedirectToSavedRoute(isAuthPopupTargetRouteForced: boolean = false): Promise<void> {
    if (this.isStandaloneAuthVisible || isAuthPopupTargetRouteForced) {
      if (this.authPopupTargetRoute) {
        try {
          await this.$router.push(this.authPopupTargetRoute);
          this.$store.dispatch('authStore/clearAuthPopupTargetRoute');
        } catch {
          /* ignore */
        }
      } else {
        if (!this.isRedirectedByQueryEventParam()) {
          this.redirectToDefaultRoute();
        }
      }

      this.isCheckLoginPopupVisible = false;
      this.isSignInPasswordPopupVisible = false;
      this.isSignUpPasswordPopupVisible = false;
      this.isConfirmCodePopupVisible = false;
      this.isTicketCodePopupVisible = false;

      return;
    }

    try {
      if (this.isRedirectedByQueryEventParam()) {
        return;
      }
      await this.$router.push(this.$route.params.redirect || { name: 'home' });
    } catch {
      /* ignore */
    }
  }

  public isRedirectedByQueryEventParam(): boolean {
    if (!this.$route.params.redirect && this.$route.query && this.$route.query.event && !Array.isArray(this.$route.query.event) && /^\d+$/.test(this.$route.query.event)) {
      const targetRouteName = UtilsHelper.getDefaultRouteNameFromEventSettings(this.eventSettings) || 'promo-live';
      this.$router.push({
        name: targetRouteName !== 'event-info' ? targetRouteName : 'promo-live',
        params: {
          eventId: this.$route.query.event,
        }
      });
      return true;
    }
    return false;
  }

  public redirectToDefaultRoute(): void {
    if (this.$route.name !== 'event-info') {
      return;
    }
    const defaultRouteName = UtilsHelper.getDefaultRouteNameFromEventSettings(this.eventSettings);
    if (defaultRouteName && defaultRouteName !== 'event-info') {
      try {
        this.$router.push({
          name: defaultRouteName,
          params: {
            eventId: this.$route.params.eventId
          },
        }).catch(() => {
          /* ignore */
        });
      } catch {
        /* ignore */
      }
    }
  }

  public onWhereToGetCodeClick(): void {
    this.whereToGetCodeMessage = this.$t('authPage.screens.answerWhereGetCode') as string;
  }

  public backToPassword(): void {
    this.stopResendCodeCounter();
    this.isConfirmCodePopupVisible = false;
    this.isSignInPasswordPopupVisible = this.isEmailRegistered;
    this.isSignUpPasswordPopupVisible = !this.isEmailRegistered;

    this.$nextTick(() => {
      (this.isEmailRegistered ? this.refSignIn : this.refSignUp).inputElement.focus();
    });
  }

  public dataLayerPush(options: any): void {
    const dataLayer = (window && (window as any).dataLayer) || [];
    try {
      dataLayer.push(options);
    } catch {}
  }

}
