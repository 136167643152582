


import {Component, Prop, Vue} from 'vue-property-decorator';
import {TContact} from '@/_types/contact.type';
import EwAvatar from '@/_modules/standalone-company/components/UI/ew-avatar/ew-avatar.vue';

@Component({components: {EwAvatar}})
export default class ContactData extends Vue {

  @Prop()
  public readonly contact: TContact;

  public get contactItem(): TContact {
    return this.contact;
  }

  public get contactName(): string {
    return this.contactItem && this.contactItem.name;
  }

  public get contactSurname(): string {
    return this.contactItem && this.contactItem.surname;
  }

  public get contactCompanyPosition(): string {
    return this.contactItem && this.contactItem.company_position;
  }

  public get contactCompanyDescription(): string {
    return this.contactItem && this.contactItem.company_description;
  }

}
