


import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TUser } from '@/_types/user.type';
import { TMeeting } from '@/_types/meeting/meeting.type';
import { TEvent } from '@/_types/event.type';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import EwAvatarCompany from '@/_modules/standalone-company/components/UI/ew-avatar-company/ew-avatar-company.vue';
import TeamMembers from '@/_modules/standalone-company/components/team-members/team-members.vue';

@Component({
  components: {
    EwButton,
    EwAvatarCompany,
    TeamMembers
  }
})
export default class Company extends Vue {

  @Getter('meetingsStore/getMeetingsByUserId') public readonly meetingsByUserId: (userId: number) => TMeeting[];
  @Action('meetingsStore/requestUserMeetings') public requestUserMeetings: (params: { userId: number; force?: boolean }) => Promise<TMeeting[]>;
  @Action('meetingsStore/setEventId') public setMeetingsStoreEventId: (eventId: number) => void;

  public companyImage: string = 'https://fr-prod-1301920146.cos.eu-frankfurt.myqcloud.com/2510/1d1d76ae-9a25-499c-9f19-ee50b61fe71f.jpeg';

  public get userId(): number {
    if (!this.user || !this.user.id) {
      return null;
    }
    return this.user.id;
  }

  public get myMeetings(): TMeeting[] {
    if (!this.userId) {
      return [];
    }
    return this.meetingsByUserId(this.userId) || [];
  }

  @Prop()
  public readonly event: TEvent;

  @Prop()
  public readonly company: TPromoPage;

  @Prop()
  public readonly user: TUser;

  @Watch('userId', {immediate: true})
  private onUserIdChange(): void {
    this.initMeetings();
  }

  private async initMeetings(): Promise<void> {
    this.setMeetingsStoreEventId(this.event.id);
    if (this.userId) {
      await this.requestUserMeetings({userId: this.userId});
    }
  }

  private onCompanyWebsiteClick(): void {
    const body: HTMLBodyElement = document.getElementsByTagName('body')[0];
    const tempLink: HTMLAnchorElement = document.createElement('a');
    tempLink.href = this.company.website;
    tempLink.setAttribute('target', '_blank');
    tempLink.setAttribute('style', 'position: fixed; top: -1000px; left: -1000px;');
    body.appendChild(tempLink);
    tempLink.click();
    tempLink.parentNode.removeChild(tempLink);
  }
}
