


import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {TMeeting, TTimeSlot} from '@/_types/meeting/meeting.type';
import DateTimeHelper from '@/_helpers/date-time.helper';
import {TEvent} from '@/_types/event.type';
import {TContact} from '@/_types/contact.type';
import {Action, Getter} from 'vuex-class';
import _cloneDeep from 'lodash.clonedeep';
import {TSimpleTimeSlot} from '@/_modules/standalone-company/types/standalone-company-store-state.type';
import {DateTimeFormat} from '@/_types/date-time-format.enum';
import {MeetingStatus} from '@/_modules/meeting-rooms/types/meeting-status.enum';

export enum MeetingTimeSlotPickerMode {
  STANDALONE_COMPANY = 'standalone-company',
  DATE_EMITTER = 'date-emitter',
}

@Component
export default class TimePicker extends Vue {

  @Getter('meetingsStore/getMeetingsByUserId') public readonly meetingsByUserId: (userId: number) => TMeeting[];
  @Action('meetingsStore/requestUserMeetings') requestUserMeetings: (params: { userId: number; force?: boolean }) => Promise<TMeeting[]>;
  @Action('standaloneCompanyStore/setTimeSlotSelection') setTimeSlotSelection: (params: TSimpleTimeSlot[]) => void;
  @Getter('standaloneCompanyStore/getTimeSlotSelection') public readonly getTimeSlotSelection: TSimpleTimeSlot[];

  public timeSlots: TSimpleTimeSlot[] = [];
  public timeSlotSelection: TSimpleTimeSlot[] = [];
  public nowTimestamp: number = (new Date()).getTime();
  public nowTimestampUpdaterIntervalId: number = null;

  @Prop({type: String, default: MeetingTimeSlotPickerMode.STANDALONE_COMPANY})
  public readonly timePickerMode: MeetingTimeSlotPickerMode;

  @Prop({type: Date, default: null})
  public readonly selectedDay: Date;

  @Prop({type: Object, default: null})
  public readonly event: TEvent;

  @Prop()
  public readonly chosenTeamMember: TContact;

  @Prop({
    type: Number,
    default: 0,
    validator: (value: number): boolean => {
      // Accept only whole numbers 0 — 23
      return Math.floor(value) === value && value >= 0 && value <= 23;
    },
  })
  public readonly timePickerHourStart: number;

  @Prop({
    type: Number,
    default: 23,
    validator: (value: number): boolean => {
      // Accept only whole numbers 0 — 23
      return Math.floor(value) === value && value >= 0 && value <= 23;
    },
  })
  public readonly timePickerHourEnd: number;

  // N.B.: The following is to satisfy the graphic design. If true, this ends slots at HH:00 instead of HH:30
  @Prop({type: Boolean, default: false})
  public readonly omitLastHalfHour: boolean;

  public get startHour(): number {
    const startHour: number = this.convertToWholeInteger(this.timePickerHourStart);
    const endHour: number = this.convertToWholeInteger(this.timePickerHourEnd);

    if ((startHour < 0) || (startHour > 23) || (startHour > endHour)) {
      return 0;
    }

    return startHour;
  }

  public get endHour(): number {
    const startHour: number = this.convertToWholeInteger(this.timePickerHourStart);
    const endHour: number = this.convertToWholeInteger(this.timePickerHourEnd);

    if ((endHour < 0) || (endHour > 23) || (startHour > endHour)) {
      return 23;
    }

    return endHour;
  }

  public get isLastHalfHourOmitted(): boolean {
    return !!this.omitLastHalfHour;
  }

  public convertToWholeInteger(number: number): number {
    return parseInt(Math.floor(number).toFixed(0), 10);
  }

  public get chosenTeamMemberUserId(): number {
    if (!this.chosenTeamMember) {
      return null;
    }
    return this.chosenTeamMember.user_id;
  }

  @Watch('selectedDay', {immediate: true})
  private onSelectedDayChange(): void {
    this.createTimeSlots();
  }

  @Watch('chosenTeamMemberUserId', {immediate: true})
  private onChosenTeamMemberUserIdChange(): void {
    if (!this.chosenTeamMemberUserId) {
      return;
    }
    this.requestUserMeetings({
      userId: this.chosenTeamMemberUserId,
      force: true,
    });
  }

  public get timeSlotButtonsHeading(): string {
    // TODO: complete with all translations
    if (this.selectedDay) {
      const localeName: string = this.$i18n.locale;
      let formatString: string = DateTimeFormat.DAY_NAME_DATE_MONTH_EN;
      if (localeName === 'uk' || localeName === 'ru') {
        formatString = DateTimeFormat.DAY_NAME_DATE_MONTH_UK;
      }
      return this.$moment(this.selectedDay).format(formatString);
    }
    return this.$t('standaloneCompany.chooseDayFirst') as string;
  }

  public get isTimePickerDisabled(): boolean {
    return !this.selectedDay;
  }

  public get meetings(): TMeeting[] {
    if (!this.chosenTeamMemberUserId) {
      return [];
    }
    return this.meetingsByUserId(this.chosenTeamMemberUserId);
  }

  public get timeSlotsPastTimeMark(): number {
    // Current time - 30 minutes, as specified in AW-1779
    // See isTimeSlotPast for comparison
    return this.nowTimestamp - (1000 * 60 * 30);
  }

  public set timeSlotsPastTimeMark(value: number) {
    this.nowTimestamp = value;
  }

  public mounted(): void {
    this.startNowTimestampUpdate();
  }

  public beforeDestroy(): void {
    this.stopNowTimestampUpdate();
  }

  private startNowTimestampUpdate(): void {
    this.nowTimestampUpdaterIntervalId = window.setInterval(() => {
      this.timeSlotsPastTimeMark = (new Date()).getTime();
    }, 1000 * 60);
  }

  private stopNowTimestampUpdate(): void {
    window.clearInterval(this.nowTimestampUpdaterIntervalId);
  }

  private createHoursRange(): number[] {
    const result: number[] = [];
    for (let i = this.startHour; i <= this.endHour; i++) {
      result.push(i);
    }
    return result;
  }

  private createTimeSlots(): void {
    this.timeSlots = [];
    let startSlotsFrom: Date = this.selectedDay;

    if (!this.selectedDay) {
      startSlotsFrom = new Date();
    }

    startSlotsFrom.setHours(0);
    startSlotsFrom.setMinutes(0);
    startSlotsFrom.setSeconds(0);
    startSlotsFrom.setMilliseconds(0);

    const selectedDateMoment = this.$moment(startSlotsFrom);
    let iterateTimeMoment = selectedDateMoment.clone()
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0);

    const hours: number[] = this.createHoursRange();

    hours.forEach(h => {
      iterateTimeMoment.hours(h).minutes(0);
      const nextTimeMoment = iterateTimeMoment.clone().add(30, 'minutes');

      this.timeSlots.push({
        dateStart: iterateTimeMoment.toDate(),
        dateEnd: nextTimeMoment.toDate(),
      });

      if (this.isLastHalfHourOmitted && h === hours[hours.length - 1]) {
        return;
      } else {
        this.timeSlots.push({
          dateStart: iterateTimeMoment.minutes(30).toDate(),
          dateEnd: nextTimeMoment.hours(h + 1).minutes(0).toDate(),
        });
      }

      iterateTimeMoment = nextTimeMoment;
    });
  }

  public getTimeSlotTime(date: Date): string {
    return DateTimeHelper.getHoursMinutes(date);
  }

  private get myMeetingsConfirmedAndUnconfirmed(): TMeeting[] {
    return this.meetings.filter(meeting => {
      return (
        meeting.is_creator === true
        && (meeting.status === 'confirmed' || meeting.status === 'unconfirmed')
      );
    });
  }

  private get myUnconfirmedMeetings(): TMeeting[] {
    return this.meetings.filter(meeting => {
      return (
        meeting.is_creator === true
        && (meeting.status === 'unconfirmed')
      );
    });
  }

  private onTimeSlotClick(timeSlot: TSimpleTimeSlot): void {
    if (this.timePickerMode === MeetingTimeSlotPickerMode.DATE_EMITTER) {
      this.onDateEmitterTimeSlotClick(timeSlot);
      return;
    }

    this.onStandaloneCompanyTimeSlotClick(timeSlot);

  }

  public onDateEmitterTimeSlotClick(timeSlot: TSimpleTimeSlot): void {
    if (this.isTimeSlotHavingMyUnconfirmedOrConfirmedMeeting(timeSlot)) {
      this.$emit('cancelMeeting', {
        meeting: this.findMyMeetingForTimeSlot(timeSlot),
      });
    } else {
      if (this.myUnconfirmedMeetings.length >= 3) {
        return;
      }
      this.$emit('requestNewMeeting', timeSlot);
    }
  }

  public findMyMeetingForTimeSlot(timeSlot: TTimeSlot): TMeeting {
    const slotMeetings: TMeeting[] = this.findAllMeetingsForTimeSlot(timeSlot);

    if (!slotMeetings || !slotMeetings.length) {
      return null;
    }

    return slotMeetings.find(meeting => {
      return (
        (meeting.status === MeetingStatus.Unconfirmed
          || meeting.status === MeetingStatus.Confirmed)
        && meeting.is_creator === true
      );
    });
  }

  public onStandaloneCompanyTimeSlotClick(timeSlot: TSimpleTimeSlot): void {
    if (this.isTimeSlotSelected(timeSlot)) {
      const timestampStart = timeSlot.dateStart.getTime();
      this.timeSlotSelection = this.timeSlotSelection.filter(item => {
        return item.dateStart.getTime() !== timestampStart;
      });
      this.setTimeSlotSelection(_cloneDeep(this.timeSlotSelection));
      return;
    }
    if (this.timeSlotSelection.length + this.myMeetingsConfirmedAndUnconfirmed.length === 3) {
      return;
    }
    this.timeSlotSelection.push(_cloneDeep(timeSlot));

    this.setTimeSlotSelection(_cloneDeep(this.timeSlotSelection));
  }

  private isTimeSlotSelected(timeSlot: TSimpleTimeSlot): boolean {
    const timestampStart = timeSlot.dateStart.getTime();
    const isInSelection = !!this.timeSlotSelection.find(item => {
      return (item.dateStart.getTime() === timestampStart);
    });
    return this.isTimeSlotHavingMyUnconfirmedOrConfirmedMeeting(timeSlot)
      || isInSelection;
  }

  private isTimeSlotOutsideEventRange(timeSlot: TSimpleTimeSlot): boolean {
    const tsStartTimestamp: number = timeSlot.dateStart.getTime();
    const tsEndTimestamp: number = timeSlot.dateEnd.getTime();

    if (!this.event) {
      return true;
    }

    const eventStartTimestamp: number = this.event.date_start.getTime();
    const eventEndTimestamp: number = this.event.date_end.getTime();

    return (
      tsStartTimestamp < eventStartTimestamp
      || tsEndTimestamp > eventEndTimestamp
    );
  }

  private isTimeSlotPast(timeSlot: TSimpleTimeSlot): boolean {
    return timeSlot.dateEnd.getTime() < this.timeSlotsPastTimeMark;
  }

  private findAllMeetingsForTimeSlot(timeSlot: TSimpleTimeSlot): TMeeting[] {
    return this.meetings.filter(meeting => {
      return meeting.date_start.getTime() === timeSlot.dateStart.getTime();
    });
  }

  private isTimeSlotMarkedNotAvailableByOwner(timeSlot: TSimpleTimeSlot): boolean {
    const slotMeetings: TMeeting[] = this.findAllMeetingsForTimeSlot(timeSlot);

    if (!slotMeetings || !slotMeetings.length) {
      return false;
    }

    return !!slotMeetings.find(meeting => {
      return (meeting.status === 'confirmed'
      // && meeting.creator_contact.user_id === this.chosenTeamMemberUserId :FIXME удалить, так слот будет отображаться занятым для всех
      // && meeting.user_contact.user_id === this.chosenTeamMemberUserId
      // && meeting.contact.user_id === this.chosenTeamMemberUserId
      );
    });

  }

  private isTimeSlotHavingMyUnconfirmedOrConfirmedMeeting(timeSlot: TSimpleTimeSlot): boolean {
    const slotMeetings: TMeeting[] = this.findAllMeetingsForTimeSlot(timeSlot);

    if (!slotMeetings || !slotMeetings.length) {
      return false;
    }

    return !!slotMeetings.find(meeting => {
      return (
        (meeting.status === MeetingStatus.Unconfirmed
        || meeting.status === MeetingStatus.Confirmed)
        && meeting.is_creator === true
      );
    });
  }
}
