


import {Vue, Component, Prop} from 'vue-property-decorator';
import {Getter, Action} from 'vuex-class';
import {TEvent} from '@/_types/event.type';
import {TPromoPage} from '@/_types/promo-page/promo-page.type';
import {TUser} from '@/_types/user.type';
import {TContact} from '@/_types/contact.type';
import {TDeletePromoPageParams} from '@/_modules/promo/api/promo-page.api';
import TimePicker from '@/_modules/standalone-company/components/time-picker/time-picker.vue';
import ContactData from '@/_modules/standalone-company/components/parts/contact-data/contact-data.vue';
// @ts-ignore
import VCalendarDatePicker from 'v-calendar/lib/components/date-picker.umd';

@Component({
  components: {
    VCalendarDatePicker,
    TimePicker,
    ContactData,
  }
})
export default class MeetingTimePicker extends Vue {

  @Getter('_eventStore/event') event: TEvent;
  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('_userStore/user') user: TUser;
  @Getter('promoPageStore/contact') myself: TContact;
  @Getter('promoPageStore/individualPromoPage') company: TPromoPage;

  @Action('_eventStore/getEvent') getEvent: (eventId: number) => Promise<TEvent>;
  @Action('promoPageStore/getPromoPage') getCompany: (params: TDeletePromoPageParams) => Promise<TPromoPage>;

  public selectedDayValue: Date = null;

  @Prop()
  public readonly chosenTeamMember: TContact;

  public get chosenTeamMemberUserId(): number {
    if (!this.chosenTeamMember) {
      return null;
    }
    return this.chosenTeamMember.user_id;
  }

  public get myUserId(): number {
    if (!this.user) {
      return null;
    }
    return this.user.id;
  }

  public get selectedDay(): Date {
    return this.selectedDayValue;
  }

  public set selectedDay(value: Date) {
    this.selectedDayValue = value;
  }

  public get calendarFromDate(): Date {
    if (!this.event) {
      return null;
    }
    const today: Date = new Date();
    const isEventStartDateInThePast: boolean = this.event.date_start.getTime() < today.getTime();
    return isEventStartDateInThePast ? today : this.event.date_start;
  }

  public get calendarStartDate(): Date {
    if (!this.event) {
      return null;
    }
    return this.event.date_start;
  }

  public get calendarEndDate(): Date {
    if (!this.event) {
      return null;
    }
    return this.event.date_end;
  }

  public get calendarAvailableDates(): { start: Date; end: Date } {
    return {
      start: this.calendarStartDate,
      end: this.calendarEndDate,
    };
  }

  public mounted(): void {
    this.createCalendarStyles();
  }

  public beforeDestroy(): void {
    this.destroyCalendarStyles();
  }

  private createCalendarStyles(): void {
    const s: HTMLStyleElement = document.createElement('style');
    s.id = 'standaloneCompanyCalendarStyles';
    s.innerHTML = this.getCustomCalendarStyles();
    document.getElementsByTagName('head')[0].appendChild(s);
  }

  private getCustomCalendarStyles(): string {
    const availableDateStart: Date = this.event.date_start;
    const availableDateEnd: Date = this.event.date_end;
    const diffDays: number = this.$moment(availableDateEnd).diff(availableDateStart, 'days');

    const isInAvailableDatesClassnames: string[] = [];

    let styleRuleResult = '';

    for (let i = 0; i <= diffDays; i++) {
      isInAvailableDatesClassnames.push(
        this.$moment(availableDateStart)
          .add(i, 'days')
          .format('[.id]-YYYY-MM-DD')
      );
    }

    const isInAvailableDatesSelector: string = isInAvailableDatesClassnames.join(', ');
    const isInAvailableDatesSelectorNotInMonth = isInAvailableDatesSelector.replace(/\.id/ig, '.is-not-in-month.id');

    styleRuleResult += isInAvailableDatesSelector + ' { background-color: rgba(0, 66, 105, 0.07); }';
    styleRuleResult += isInAvailableDatesClassnames[0] + ' { border-radius: 50% 0 0 50%; }';
    styleRuleResult += isInAvailableDatesClassnames[isInAvailableDatesClassnames.length - 1] + ' { border-radius: 0 50% 50% 0; }';
    styleRuleResult += isInAvailableDatesSelectorNotInMonth + ' { background-color: rgba(0, 66, 105, 0.035); }';

    return styleRuleResult;
  }

  private destroyCalendarStyles(): void {
    const s: HTMLStyleElement = document.getElementById('standaloneCompanyCalendarStyles') as HTMLStyleElement;
    s.parentNode.removeChild(s);
  }

}
