









import { Vue, Component } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { TEvent } from '@/_types/event.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import EwInput from '@/_modules/standalone-company/components/UI/ew-input/ew-input.vue';
import EwPassword from '@/_modules/standalone-company/components/UI/ew-password/ew-password.vue';
import { TUser } from '@/_types/user.type';
import { TContact } from '@/_types/contact.type';
import { TDeletePromoPageParams } from '@/_modules/promo/api/promo-page.api';
import BrandingHeader from '@/_modules/standalone-company/components/branding-header/branding-header.vue';
import Company from '@/_modules/standalone-company/components/company/company.vue';
import EventInfo from '@/_modules/standalone-company/components/event-info/event-info.vue';
import EwPopUp from '@/_modules/standalone-company/components/ew-pop-up/ew-pop-up.vue';
import MeetingTimePicker from '@/_modules/standalone-company/components/meeting-time-picker/meeting-time-picker.vue';
import ContactData from '@/_modules/standalone-company/components/parts/contact-data/contact-data.vue';
import EwAuth from '@/_modules/standalone-company/components/ew-auth/ew-auth.vue';

@Component({
  components: {
    BrandingHeader,
    Company,
    EwButton,
    EwInput,
    EwPopUp,
    EwPassword,
    EventInfo,
    MeetingTimePicker,
    ContactData,
    EwAuth
  }
})
export default class StandaloneCompanyView extends Vue {

  @Getter('_eventStore/event') event: TEvent;
  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('_userStore/user') user: TUser;
  @Getter('promoPageStore/contact') myself: TContact;
  @Getter('promoPageStore/individualPromoPage') company: TPromoPage;

  @Action('_eventStore/getEvent') getEvent: (eventId: number) => Promise<TEvent>;
  @Action('promoPageStore/getPromoPage') getCompany: (params: TDeletePromoPageParams) => Promise<TPromoPage>;

  public savedMetaViewportTagOuterHTML: string = '';

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get companyExternalId(): string {
    return this.$route.params.companyExternalId ? this.$route.params.companyExternalId : null;
  }

  public get metaViewport(): HTMLMetaElement {
    const d: HTMLDocument = document as HTMLDocument;
    return d.querySelector('head meta[name=viewport]');
  }

  public created(): void {
    this.initComponent();
  }

  private async initComponent(): Promise<void> {
    this.saveMetaViewportTagOuterHTML();
    this.setMetaViewportTagToAdaptive();

    const event = await this.getEvent(this.eventId);

    if (event && event.id) {
      await this.getCompany({eventId: this.eventId, externalId: this.companyExternalId});
    }
  }

  public beforeDestroy(): void {
    this.resetMetaViewportTag();
  }

  private saveMetaViewportTagOuterHTML(): void {
    this.savedMetaViewportTagOuterHTML = this.metaViewport.outerHTML;
  }

  private setMetaViewportTagToAdaptive(): void {
    this.metaViewport.setAttribute('content', 'width=device-width, initial-scale=1.0');
  }

  private resetMetaViewportTag(): void {
    this.metaViewport.outerHTML = this.savedMetaViewportTagOuterHTML;
  }

}
