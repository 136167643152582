import { render, staticRenderFns } from "./event-info.html?vue&type=template&id=ea0ab1d4&scoped=true&"
import script from "./event-info.vue?vue&type=script&lang=ts&"
export * from "./event-info.vue?vue&type=script&lang=ts&"
import style0 from "./event-info.scss?vue&type=style&index=0&id=ea0ab1d4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea0ab1d4",
  null
  
)

export default component.exports