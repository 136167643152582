


import {Vue, Component, Prop, Ref} from 'vue-property-decorator';
import {TEvent} from '@/_types/event.type';
import {DateTimeFormat} from '@/_types/date-time-format.enum';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';

@Component({
  components: {
    EwButton
  }
})
export default class EventInfo extends Vue {

  @Ref('eventWebsiteLink') eventWebsiteLink: HTMLAnchorElement;

  @Prop()
  public readonly event: TEvent;

  public get isEventInfoWrapperVisible(): boolean {
    return false; // TODO: maybe add some logic or remove the code from the template
  }

  public get eventLogo(): string {
    return (this.event && this.event.org_logo) || '';
  }

  public get eventImage(): string {
    return (this.event && this.event.image) || '';
  }

  public get eventName(): string {
    return (this.event && this.event.title) || '';
  }

  public get eventDescription(): string {
    return (this.event && this.event.descr) || '';
  }

  public get eventWebsite(): string {
    return (this.event && this.event.url) || '';
  }

  public get eventDateStart(): Date {
    return (this.event && this.event.date_start) || null;
  }

  public get eventDateEnd(): Date {
    return (this.event && this.event.date_end) || null;
  }

  public get eventDateStartStr(): string {
    if ((this.eventDateStart
      && this.eventDateEnd)
      && (this.eventDateStart.getFullYear() === this.eventDateEnd.getFullYear())) {
      return this.$moment(this.eventDateStart).format(DateTimeFormat.DAY_MONTH_TIME);
    } else {
      return this.$moment(this.eventDateStart).format(DateTimeFormat.DAY_MONTH_TIME_YEAR);
    }
  }

  public get eventImageStyle(): {[key: string]: string} {
    const result: {[key: string]: string} = {};
    if (this.eventImage) {
      result['background-image'] = `url(${this.eventImage})`;
    }
    return Object.keys(result).length ? result : null;
  }

  public eventDateEndStr(): string {
    return this.$moment(this.eventDateEnd).format(DateTimeFormat.DAY_MONTH_TIME_YEAR);
  }

  public onEventWebsiteClick(): void {
    if (this.eventWebsite) {
      const a = document.createElement('a');
      a.setAttribute('href', this.eventWebsite);
      a.setAttribute('target', '_blank');
      a.style.position = 'absolute';
      a.style.top = '-10000px';
      document.getElementsByTagName('body')[0].appendChild(a);
      a.click();
      a.parentNode.removeChild(a);
    }
  }

}
