


import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import {TContact} from '@/_types/contact.type';
import {TMeeting} from '@/_types/meeting/meeting.type';
import {TUser} from '@/_types/user.type';
import {TEvent} from '@/_types/event.type';
import {MeetingStatus} from '@/_modules/meeting-rooms/types/meeting-status.enum';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import EwAvatar from '@/_modules/standalone-company/components/UI/ew-avatar/ew-avatar.vue';
import ContactData from '@/_modules/standalone-company/components/parts/contact-data/contact-data.vue';
import _cloneDeep from 'lodash.clonedeep';

@Component({
  components: {
    EwAvatar,
    EwButton,
    ContactData
  }
})
export default class TeamMember extends Vue {
  @Getter('_eventStore/event') event: TEvent;
  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('_userStore/user') user: TUser;
  @Getter('meetingsStore/getMeetingsByUserId') public readonly meetingsByUserId: (userId: number) => TMeeting[];
  @Action('meetingsStore/requestUserMeetings') public requestUserMeetings: (params: { userId: number; force?: boolean }) => Promise<TMeeting[]>;
  @Action('meetingsStore/setEventId') public setMeetingsStoreEventId: (eventId: number) => void;

  public monthNames: string[] = [
    this.$t('datepicker.monthNamesFull.january') as string,
    this.$t('datepicker.monthNamesFull.february') as string,
    this.$t('datepicker.monthNamesFull.march') as string,
    this.$t('datepicker.monthNamesFull.april') as string,
    this.$t('datepicker.monthNamesFull.may') as string,
    this.$t('datepicker.monthNamesFull.june') as string,
    this.$t('datepicker.monthNamesFull.july') as string,
    this.$t('datepicker.monthNamesFull.august') as string,
    this.$t('datepicker.monthNamesFull.september') as string,
    this.$t('datepicker.monthNamesFull.october') as string,
    this.$t('datepicker.monthNamesFull.november') as string,
    this.$t('datepicker.monthNamesFull.december') as string,
  ];

  public dayNames: string[] = [
    this.$t('standaloneCompany.dayNames.sunday') as string,
    this.$t('standaloneCompany.dayNames.monday') as string,
    this.$t('standaloneCompany.dayNames.tuesday') as string,
    this.$t('standaloneCompany.dayNames.wednesday') as string,
    this.$t('standaloneCompany.dayNames.thursday') as string,
    this.$t('standaloneCompany.dayNames.friday') as string,
    this.$t('standaloneCompany.dayNames.saturday') as string,
  ];

  @Prop()
  public readonly contact: TContact;

  public get userId(): number {
    if (!this.user || !this.user.id) {
      return null;
    }
    return this.user.id;
  }

  public get myMeetings(): TMeeting[] {
    if (!this.userId) {
      return [];
    }
    return this.meetingsByUserId(this.userId) || [];
  }

  public get isTeamMemberMyself(): boolean {
    if (!this.isAuthenticated || !this.userId || !this.contact) {
      return false;
    }
    return this.userId === this.contact.user_id;
  }

  public get actualMeetings(): TMeeting[] {
    return this.myMeetings.filter(item => {
      return (
        (
          item.status === MeetingStatus.Unconfirmed
          || item.status === MeetingStatus.Confirmed
        )
        && item.is_creator
      );
    });
  }

  public get teamMemberMeetings(): TMeeting[] {
    if (this.contact && this.contact.id) {
      return this.actualMeetings.filter(item => {
        return item.contact.id === this.contact.id;
      });
    } else {
      return [];
    }
  }

  @Watch('userId', {immediate: true})
  private onUserIdChange(): void {
    this.initMeetings();
  }

  @Watch('myMeetings', {deep: true})
  private onMyMeetingsChange(): void {
    this.initMeetings();
  }

  // TODO: move into a helper
  private getFormattedMeetingInfo(dateStart: Date, dateEnd: Date): string {
    const diffMinutes: number = Math.floor((dateEnd.getTime() - dateStart.getTime()) / 1000 / 60);
    const year: number = dateStart.getFullYear();
    const monthName: string = this.monthNames[dateStart.getMonth()];
    const dayName: string = this.dayNames[dateStart.getDay()];
    const dayNumber: number = dateStart.getDate();
    const hhStart: number = dateStart.getHours();
    const mmStart: number = dateStart.getMinutes();
    const hhEnd: number = dateEnd.getHours();
    const mmEnd: number = dateEnd.getMinutes();

    return this.$t('standaloneCompany.meetingSuccessTemplate', {
      diffMinutes,
      year,
      monthName,
      dayNumber,
      dayName,
      hhmmStart: hhStart.toFixed(0).padStart(2, '0') + ':' + mmStart.toFixed(0).padStart(2, '0'),
      hhmmEnd: hhEnd.toFixed(0).padStart(2, '0') + ':' + mmEnd.toFixed(0).padStart(2, '0'),
    }) as string;
  }

  private async initMeetings(): Promise<void> {
    this.setMeetingsStoreEventId(this.event.id);
    if (this.userId) {
      await this.requestUserMeetings({userId: this.userId});
    }
  }

  public openScheduleMeetingPopup(): void {
    this.$emit('openScheduleMeetingPopup', this.contact);
  }

  private onCancelMeetingClick(): void {
    const meetingsToCancel: TMeeting[] = _cloneDeep(this.actualMeetings)
      .filter(meeting => {
        return meeting.contact.id === this.contact.id;
      });
    this.$emit('cancelMeetings', meetingsToCancel, this.contact);
  }

}
